/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { HomepageFeaturedProductCard } from '@/components/blocks/HomepageFeaturedProductCard';
import { useProduct } from '@/data/Content/Product';
import { FC } from 'react';

export const HomepageFeaturedProduct: FC<{ partNumber: string; clickAction?: () => void }> = ({
	partNumber,
	clickAction,
}) => {
	const { product, loading } = useProduct({ id: partNumber });
	return loading || !product ? null : (
		<HomepageFeaturedProductCard product={product} clickAction={clickAction}></HomepageFeaturedProductCard>
	);
};
