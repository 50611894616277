import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class HealthCheck<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
 * No description
 *
 * @tags Health Check
 * @name HealthControllerCheck
 * @request GET:/health
 * @response `200` `{
  \** @example "ok" *\
    status?: string,
  \** @example {"database":{"status":"up"}} *\
    info?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {} *\
    error?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"database":{"status":"up"}} *\
    details?: Record<string,{
    status?: string,
    [key: string]: any,

}>,

}` The Health Check is successful
 * @response `503` `{
  \** @example "error" *\
    status?: string,
  \** @example {"database":{"status":"up"}} *\
    info?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"redis":{"status":"down","message":"Could not connect"}} *\
    error?: Record<string,{
    status?: string,
    [key: string]: any,

}>,
  \** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} *\
    details?: Record<string,{
    status?: string,
    [key: string]: any,

}>,

}` The Health Check is not successful
 */
	healthControllerCheck = (params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['healthControllerCheck'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/health`,
				params: _params,
				methodName: 'healthControllerCheck',
			};
			logger.trace(traceData);
		}
		return this.http.request<
			{
				/** @example "ok" */
				status?: string;
				/** @example {"database":{"status":"up"}} */
				info?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
				/** @example {} */
				error?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
				/** @example {"database":{"status":"up"}} */
				details?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
			},
			{
				/** @example "error" */
				status?: string;
				/** @example {"database":{"status":"up"}} */
				info?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
				/** @example {"redis":{"status":"down","message":"Could not connect"}} */
				error?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
				/** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
				details?: Record<
					string,
					{
						status?: string;
						[key: string]: any;
					}
				>;
			}
		>({
			path: `/health`,
			method: 'GET',
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
