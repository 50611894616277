import { keyBy } from 'lodash';
import { HttpClient } from './http-client';
import { HealthCheck } from './HealthCheck';
import { ModelResults } from './ModelResults';

const traceDetails = process.env.TRACE_DETAILS?.trim() ? keyBy(process.env.TRACE_DETAILS.split(',').map((f) => f.trim()).filter(Boolean)) : undefined;

const cache = {
	PUBLIC_CLIENT: {} as Record<string, HttpClient>,
	PRIVATE_CLIENT: {} as Record<string, HttpClient>,
};
const getClient = (pub: boolean, baseUrl: string, traceDetails?: Record<string, string>) => {
	const root = pub ? 'PUBLIC_CLIENT' : 'PRIVATE_CLIENT';
	if (!cache[root][baseUrl]) {
		cache[root][baseUrl] = new HttpClient({ baseUrl, traceDetails, isPublic: !!pub });
	}
	return cache[root][baseUrl];
};
const mockURL = `http://localhost:${process.env.MOCK_HOST_PORT}`;
const getBaseOrMockURL = (baseUrl: string) => `${process.env.USE_MOCK === 'true' ? mockURL : baseUrl}/model-results-api/`;

export const commerceAIHealthCheck = (pub: boolean, baseUrl: string) => new HealthCheck(getClient(pub, getBaseOrMockURL(baseUrl), traceDetails));
export const commerceAIModelResults = (pub: boolean, baseUrl: string) => new ModelResults(getClient(pub, getBaseOrMockURL(baseUrl), traceDetails));
