import { ParamResult } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class ModelResults<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags Model Results
	 * @name ModelResultsControllerFindOne
	 * @request GET:/v1/projects/{projectExtKey}/model-results/{modelInterfaceExtKey}
	 * @response `200` `(ParamResult)[]`
	 */
	modelResultsControllerFindOne = (
		projectExtKey: string,
		modelInterfaceExtKey: string,
		query?: {
			/** Value of the external parameter (if any) to get results. For example, product specific models such as More Like This will require a product ID. */
			externalParam?: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['modelResultsControllerFindOne'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/v1/projects/${projectExtKey}/model-results/${modelInterfaceExtKey}`,
				params: _params,
				query: query,
				methodName: 'modelResultsControllerFindOne',
			};
			logger.trace(traceData);
		}
		return this.http.request<ParamResult[], any>({
			path: `/v1/projects/${projectExtKey}/model-results/${modelInterfaceExtKey}`,
			method: 'GET',
			query: query,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
