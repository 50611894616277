/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomProgressIndicator } from '@/components/blocks/CustomProgressIndicator';
import { FC, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HomepageFeaturedProduct } from '@/components/content/HomepageFeaturedProduct';
import { FeaturedProductCarousel } from '@/components/blocks/FeaturedProductCarousel';
import { StaticSlider } from '@/components/blocks/Carousel/StaticSlider';
import { carouselSlideSX } from '@/components/content/CatalogEntryRecommendation/styles/carouselSlide';
import { useCatalogEntryRecommendation } from '@/data/Content/CatalogEntryRecommendation';
import { WidgetProperties } from '@/data/types/Slot';
import { ID } from '@/data/types/Basic';
import { CarouselOptions } from '@/data/types/Carousel';
import { useLocalization } from '@/data/Localization';

const emptyProperties = {} as WidgetProperties;

export const CatalogEntryRecommendation: FC<{ id: ID; properties?: WidgetProperties }> = ({
	id: _id,
	properties = emptyProperties,
}) => {
	const { emsName = '' } = properties;
	const { partNumbers, clickAction, loading } = useCatalogEntryRecommendation(emsName);
	const slides = useMemo(
		() =>
			(partNumbers || []).map((partNumber) => (
				<HomepageFeaturedProduct
					key={partNumber}
					partNumber={partNumber}
					clickAction={clickAction.bind(null, partNumber)}
				/>
			)),
		[partNumbers, clickAction]
	);
	const a11yProps = useMemo(
		() =>
			(partNumbers || []).map((partNumber) => ({
				'aria-label': undefined,
				'aria-labelledby': partNumber,
			})),
		[partNumbers]
	);

	const theme = useTheme();
	const lgMatch = useMediaQuery(theme.breakpoints.down('lg'));
	const mdMatch = useMediaQuery(theme.breakpoints.down('md'));
	const smMatch = useMediaQuery(theme.breakpoints.down('sm'));
	const visibleSlides = useMemo(
		() => (smMatch ? 2 : mdMatch ? 5 : lgMatch ? 5 : 5),
		[lgMatch, mdMatch, smMatch]
	);

	const disabledSliding = useMemo(() => visibleSlides >= slides.length, [visibleSlides, slides]);
	const FeaturedProductsNLS = useLocalization('FeaturedProducts');
	const carouselProps: CarouselOptions = {
		naturalSlideWidth: 179,
		naturalSlideHeight: 300,
		visibleSlides,
		step: visibleSlides,
		infinite: true,
		dragEnabled: false,
		totalSlides: slides.length,
		isIntrinsicHeight: true,
	};
	const title: string = properties?.titleInput !== 'Null' ? properties?.titleInput : FeaturedProductsNLS.title.t();

	return (
		<>
			{loading ? (
				<CustomProgressIndicator />
			) : disabledSliding ? (
				<Box sx={{ background: 'white', marginLeft: 'calc((100% - 100vw) / 2)', width: '100vw', padding: 0, maxWidth: 'none' }}>
					<Typography variant={'h2'} align='center' pt={6} pb={4} >{title}</Typography>
					<StaticSlider>{slides}</StaticSlider>
				</Box>
			) : (
				<Box sx={{ background: 'white', marginLeft: 'calc((100% - 100vw) / 2)', width: '100vw', padding: 0, maxWidth: 'none' }}>
					<Typography variant={'h2'} align='center' pt={6} pb={4} >{title}</Typography>
					<Box sx={{ '> .carousel': { maxWidth: '1132px' } }}><FeaturedProductCarousel
						a11yProps={a11yProps}
						slides={slides}
						carouselProps={carouselProps}
						carouselSlideStyles={carouselSlideSX}
					></FeaturedProductCarousel></Box></Box>
			)}
		</>
	);
};
