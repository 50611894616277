/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const productCardMediaSX: SxProps<Theme> = {
	mb: '8px',
	backgroundSize: 'contain',
	position: 'relative',
	height:'180px',
	// height: (theme) => theme.dimensions.productCard.featuredProductCardThumbnail + 'px',
};
