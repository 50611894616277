/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useMediaQuery, useTheme } from '@mui/material';
import { Linkable } from '@/components/blocks/Linkable';
import { MuiCardMedia } from '@/components/blocks/MuiCardMedia';
import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { productCardSX } from '@/components/blocks/HomepageFeaturedProductCard/styles/card';
import {
	productCardContentSX,
	productNameSX,
	productSubTextSX,
	productReviewSx,
	productPriceSX,
	productReviewTypography,
	productNameLinkSX,
} from '@/components/blocks/HomepageFeaturedProductCard/styles/cardContent';
import { productCardMediaSX } from '@/components/blocks/HomepageFeaturedProductCard/styles/cardMedia';
import { useProductCard } from '@/data/Content/_ProductCard';
import { useLocalization } from '@/data/Localization';
import { ProductType } from '@/data/types/Product';
import { CardContent, Typography, Card, Rating, Box } from '@mui/material';
import HTMLReactParser from 'html-react-parser';
import { FC, useEffect } from 'react';
import { getAttributeValue } from '@/utils/getValueFromIdentifier';
import { S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP } from '@/data/constants/product';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ProductDetailsPrice } from '@/components/blocks/ProductDetails/Price';
import { findPrice } from '@/components/content/Bundle/parts/Table';
import { useRouter } from 'next/router';

export const HomepageFeaturedProductCard: FC<{
	product: ProductType;
	clickAction?: () => void;
}> = ({ product, clickAction }) => {
	const priceDisplayNLS = useLocalization('PriceDisplay');
	const { sku } = useProductCard(product);
	const theme = useTheme();
	const lgMatch = useMediaQuery(theme.breakpoints.down('lg'));
	const attributeArray = product?.attributes;
	const ratingValue = parseFloat(getAttributeValue('BVRating', attributeArray));

	const router = useRouter();
	const path = router?.query?.path;

	useEffect(() => {
		const mainElement = document?.querySelector('main');
		if (router?.query?.iscustom) {
			router.reload();
		}
		mainElement && path?.includes('advantage-training') ? (mainElement.style.overflowX = 'hidden') : null;

		return () => {
			if (mainElement) mainElement.style.overflow = '';
		};
	}, [path, router]);

	return (
		<Card onClick={clickAction} sx={productCardSX}>
			<Linkable href={product.seo?.href}>
				<MuiCardMedia
					sx={productCardMediaSX}
					component="div"
					image={S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP + (sku ?? product).thumbnail}
					title={product.name}
				></MuiCardMedia>
			</Linkable>
			<CardContent sx={productCardContentSX}>
				<Box>
					<Linkable href={product.seo?.href} sx={productNameLinkSX}>
						<Typography variant="body2" align="left" id={product.partNumber} sx={productNameSX}>
							{product.name}
						</Typography>
					</Linkable>

					<Typography variant="body2" align="left" sx={productReviewTypography}>
						<Rating
							value={isNaN(ratingValue) || ratingValue === 0 ? null : ratingValue}
							precision={0.5}
							readOnly
							/* icon={<StarBorderIcon style={{ fill: 'EFAE50', color: '#EFAE50' }} fontSize="inherit" />}
							emptyIcon={<StarBorderIcon style={{ color: '#EFAE50' }} fontSize="inherit" />} */
							sx={productReviewSx}
						/>
					</Typography>

				</Box>
			</CardContent>
		</Card>
	);
};
