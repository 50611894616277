/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const productCardContentSX: SxProps<Theme> = (theme: Theme) => ({
	py: 0,
	flex: '1',
	marginBottom: theme.spacing(1),
	padding: 0,
	'&:last-child': {
		paddingBottom: 0,
		marginBottom: 0,
	},
});
export const productNameSX: SxProps<Theme> = (theme: Theme) => ({
	fontWeight: 600,
	marginBottom: theme.spacing(0.5),
	color: '#323638',
});

export const productNameLinkSX: SxProps<Theme> = (theme: Theme) => ({
	'&: hover': {
		textDecoration: 'none',
	},
});

export const productSubTextSX: SxProps<Theme> = (theme: Theme) => ({
	marginBottom: theme.spacing(0.25),
	marginTop: theme.spacing(0.25),
});

export const productReviewSx: SxProps<Theme> = (theme: Theme) => ({
	fontSize: '14px',
	top: '2px',
	'& .MuiRating-iconEmpty': {
		color: '#EFAE50 !important',
	},
	'& .MuiRating-iconFilled': {
		color: '#EFAE50 !important',
		fill: '#EFAE50 !important',
	},
});

export const productReviewTypography: SxProps<Theme> = (theme: Theme) => ({
	marginBottom: theme.spacing(0.25),
	marginTop: theme.spacing(0.25),
});

export const productPriceSX: SxProps<Theme> = (theme: Theme) => ({
	fontWeight: 600,
});
