/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Padding } from '@mui/icons-material';
import { SxProps } from '@mui/material';

export const productCardSX: SxProps = {
	// width: '197px',
	// height: '399px',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 0,
	boxShadow: 0,
	// background:'transparent',
	'&:hover': {
		boxShadow: 0,
	},
};
export const stackSX: SxProps = {
	'&>:not(style)+:not(style)': {
		margin: 0,
	},
};
